import { Button, Card, Col, message, Row, Table, Input, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "res";
import { fetchAllProjects, deleteProject } from "redux/features/projects";
import { getUserProfile } from "redux/features/auth";
import moment from "moment";

// Format the price above to USD using the locale, style, and currency.
let IDRFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'IDR',
});

const LocalizedModal = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title="Modal"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="Ok"
        cancelText="Cancel"
      >
        <p>Apa anda yakin?</p>
      </Modal>
    </>
  );
};

export const MYPROJECTS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState({});
  const [modal, contextHolder] = Modal.useModal();

  const [filters, setFilters] = useState({
    page: 1,
    limit: 1000,
    status: "CERTIFIED",
  });

  const { Search } = Input;

  const [role, setRole] = useState(0);
  const [company, setCompany] = useState("");
  const getProfile = async () => {
    try {
      const response = await dispatch(getUserProfile()).unwrap();
      setRole(response.data.user.role_id)
      setCompany(response.data.user.perusahaan)
      getData({
        perusahaan:response.data.user.perusahaan
      });
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Verifikasi",
      dataIndex: "verification",
      key: "verification",
      render: (_, record) => {
        if (record.verification === "WAITING") {
          return <div className="text-green text-center">
            <b style={{ color: "orange", font: "bold" }}>WAITING</b>
          </div>
        }
        else if (record.verification === "SEMI-VERIFIED") {
          return <div className="text-green text-center">
            <b style={{ color: "blue", font: "bold" }}>SEMI-VERIFIED</b>
          </div>
        }
        else if (record.verification === "REJECTED") {
          return <div className="text-green text-center">
            <b style={{ color: "red", font: "bold" }}>REJECTED</b>
          </div>
        }
        else {
          return <div className="text-green text-center">
            <b style={{ color: "green", font: "bold" }}>VERIFIED</b>
          </div>
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        if (record.status === "OPEN") {
          return <div className="text-green text-center">
            <b style={{ color: "orange", font: "bold" }}>OPEN</b>
          </div>
        }
        else if (record.status === "CERTIFIED") {
          return <div className="text-green text-center">
            <b style={{ color: "green", font: "bold" }}>CERTIFIED</b>
          </div>
        }
        else {
          return <div className="text-green text-center">
            <b style={{ color: "red", font: "bold" }}>CLOSED</b>
          </div>
        }
      },
    },
    {
      title: "Kategori",
      dataIndex: "kategori",
      key: "kategori",
    },
    // {
    //   title: "Alamat Lengkap",
    //   dataIndex: "lokasi",
    //   key: "lokasi",
    // },
    {
      title: "Kabupaten",
      dataIndex: "kabupaten",
      key: "kabupaten",
    },
    {
      title: "OPD",
      dataIndex: "opd",
      key: "opd",
    },
    // {
    //   title: "Kecamatan",
    //   dataIndex: "kecamatan",
    //   key: "kecamatan",
    // },
    // {
    //   title: "Kelurahan",
    //   dataIndex: "kelurahan",
    //   key: "kelurahan",
    // },
    {
      title: "Nominal",
      dataIndex: "cost",
      key: "cost",
      render: (_, record) => (
        <div>
          {IDRFormat.format(record.cost)}
        </div>
      ),
    },
    {
      title: "Penyedia Dana",
      dataIndex: "perusahaan",
      key: "perusahaan",
    },
    {
      title: "Pelaksana",
      dataIndex: "pelaksana",
      key: "pelaksana",
      render: (_, record) => (
        <div>
          {record.pelaksana ? <div style={{ color: "green", font: "bold" }}>{record.pelaksana}</div> : <div style={{ color: "red", font: "bold" }}>Belum Ditentukan</div>}
        </div>
      ),
    }, 
    
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (_, record) => (
        <div>
          {moment(record.startDate).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      title: () => <div className="text-center">Detail</div>,
      key: "status",
      render: (_, record) => (
        <div className="text-center">
          <Button
            type="primary"
            style={{ textAlign: "center" }}
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_project_read_only}`,
                state: record,
              });
            }}
          >
            Detail
          </Button>
        </div>
      ),
    },
    // {
    //   title: () => <div className="text-center">Action</div>,
    //   key: "status",
    //   render: (_, record) => {
    //     if(role === 1){
    //       return (
    //         <div className="text-center">
    //         <Button
    //           type="danger"
    //           style={{ textAlign: "center", color: "white" }}
    //           onClick={() => {
    //             confirm(record?.id);
    //           }}
    //         >
    //           Delete
    //         </Button>
    //       </div>
    //       )
    //     }else{
    //       return (
    //         <div className="text-center">
    //         <Button
    //           type="primary"
    //           style={{ textAlign: "center", color: "white" }}
    //           onClick={() => {
    //             // confirm(record?.id);
    //           }}
    //         >
    //           Ambil Proyek
    //         </Button>
    //       </div>
    //       )
    //     }
    //   }
    // },
  ];

  const getData = async (params) => {
    try {
      const user = await dispatch(getUserProfile()).unwrap();
      const response = await dispatch(fetchAllProjects({
        // owner:role === 4 ? user.data.user.perusahaan : undefined,
        status:"CERTIFIED"
      })).unwrap();
      setData(response.data.Projects);
      setMetaData(response.meta);
      setLoading(false);
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    //sort by date_published
    let sorterKey = "";
    // if (sorter.field === "date_published" && sorter.order) {
    //   sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
    //   setFilters({ ...filters, page: 1, sort: sorterKey });
    // }

    // if (sorter.field === "created_at" && sorter.order) {
    //   sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
    //   setFilters({ ...filters, page: 1, sort: sorterKey });
    // }

    setFilters({ ...filters, page: pagination.current, sort: sorterKey });
  };

  const confirm = (id) => {
    modal.confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure wanna delete this?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await dispatch(deleteProject(id));
        getData();
      },
      onCancel: () => { },
    });
  };

  useEffect(() => {
    getProfile();
  }, [filters]);

  return (
    <>
      <LocalizedModal></LocalizedModal>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Proyek CSR Saya (SELESAI/TERSERTIFIKASI)</h2>
          <p>Daftar semua proyek CSR yang sudah selesai</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Row gutter={[6,6]}>
              <Col md={6} xl={6} sm={24} >
                <Input placeholder="Cari Berdasarkan Judul Proyek"></Input>
              </Col>
              <Col md={6} xl={6} sm={24} >
                <Input placeholder="Cari Berdasarkan Kota/Kabupaten"></Input>
              </Col>
              <Col md={4} xl={4} sm={24} >
                <Input placeholder="Cari Berdasarkan OPD"></Input>
              </Col>
              <Col md={4} xl={4} sm={24} >
                <Input placeholder="Cari Berdasarkan Kategori"></Input>
              </Col>
              <Col md={4} xl={4} sm={24} >
                <Button type="primary" style={{width:"100%"}}>Cari</Button>
              </Col>
            </Row>
            <Table
              className="no-border-last"
              columns={tableColumns}
              scroll={{ x: 'max-content' }} // enables horizontal scrolling
              dataSource={data}
              rowKey="id"
              pagination={{
                defaultPageSize: 10,
                defaultCurrent: 1,
                total: metaData.total_data,
              }}
              onChange={handleTableChange}
            />
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button
            type="primary"
            style={{ border: "0px" }}
            htmlType="submit"
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_project}`,
              });
            }}
            block
          >
            Tambahkan Proyek TJSL
          </Button>
        </Col>
      </Row> */}
      {contextHolder}
    </>
  );
};

export default withRouter(MYPROJECTS);
