import { strings } from 'res';
import { DashboardIcon, OrderIcon, ReportIcon, DeliveryIcon } from "../assets/svg/icon";
import { UserOutlined, DatabaseOutlined , CopyOutlined , SettingOutlined, CheckCircleOutlined, AimOutlined, FileOutlined, BuildOutlined, DownCircleOutlined } from '@ant-design/icons';

import { all_privileges, admin_privileges, all_projects, opd_privileges, my_projects_privileges, verifikator, finished_projects, sertifikator } from "res/privileges/index";

const dashBoardNavTree = [{
  key: "Home",
  path: strings.navigation.path.dashboard,
  title: "Home",
  breadcrumb: false,
  submenu: [
    {
      key: "Dashboard",
      path: strings.navigation.path.dashboard,
      title: "Dashboard",
      icon: DashboardIcon,
      breadcrumb: false,
      access: all_privileges,
      submenu: []
    },
    {
      key: "Proyek Saya",
      path: strings.navigation.path.my_projects,
      title: "Proyek Saya",
      icon: FileOutlined,
      breadcrumb: false,
      access: my_projects_privileges,
      submenu: []
    },
    {
      key: "Proyek Diajukan",
      path: strings.navigation.path.proyek_opd,
      title: "Proyek Diajukan",
      icon: BuildOutlined,
      breadcrumb: false,
      access: opd_privileges,
      submenu: []
    },
    {
      key: "Proyek Tersedia",
      path: strings.navigation.path.open_projects,
      title: "Proyek Tersedia",
      icon: DeliveryIcon,
      breadcrumb: false,
      access: my_projects_privileges,
      submenu: []
    },
    {
      key: "Proyek Selesai",
      path: strings.navigation.path.finished_projects,
      title: "Proyek Selesai",
      icon: CheckCircleOutlined,
      breadcrumb: false,
      access: finished_projects,
      submenu: []
    },
    {
      key: "Verifikasi Proyek",
      path: strings.navigation.path.verification_projects,
      title: "Verifikasi Proyek",
      icon: DatabaseOutlined,
      breadcrumb: false,
      access: verifikator,
      submenu: []
    },
    {
      key: "Sertifikasi Proyek",
      path: strings.navigation.path.verification_projects,
      title: "Sertifikasi Proyek",
      icon: CopyOutlined,
      breadcrumb: false,
      access: sertifikator,
      submenu: []
    },
    {
      key: "Semua Proyek TJSL",
      path: strings.navigation.path.projects,
      title: "Semua Proyek TJSL",
      icon: AimOutlined,
      breadcrumb: false,
      access: admin_privileges,
      submenu: []
    },
    {
      key: "BAST",
      path: strings.navigation.path.contents,
      title: "BAST",
      icon: ReportIcon,
      breadcrumb: false,
      access: admin_privileges,
      submenu: []
    },
    {
      key: "Instansi",
      path: strings.navigation.path.wajib_pajak,
      title: "Instansi",
      icon: OrderIcon,
      breadcrumb: false,
      access: admin_privileges,
      submenu: []
    },
    {
      key: "Pengguna",
      path: strings.navigation.path.users,
      title: "Pengguna",
      icon: UserOutlined,
      access: admin_privileges,
      breadcrumb: false,
      submenu: []
    },
  ]
}]

const ControlTree = [{
  key: "Pengaturan",
  path: strings.navigation.path.settings,
  title: "Pengaturan",
  breadcrumb: false,
  submenu: [
    {
      key: "Pengaturan",
      path: strings.navigation.path.settings,
      title: "Pengaturan",
      icon: SettingOutlined,
      breadcrumb: false,
      access: all_privileges,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...ControlTree,
]

export default navigationConfig;